.App {
  text-align: center;
}

.App-header {
  background-color: #01adff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

canvas {
  border: 1px solid white;
}

input,
button {
  margin: 10px;
}

h1,
h2 {
  cursor: pointer;
}

.control-buttons {
  display: flex;
  justify-content: flex-end;
  width: 300px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.control-buttons button {
  margin-left: 10px;
}

.react-rnd {
  position: absolute;
}

.react-rnd-resizable-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
  background-color: white;
}

.overlay-container {
  position: relative;
}
